import {
  StyledFooterBox,
  StyledHomeScreenLeftBox,
  StyledHomeScreenLeftScrollBox,
  StyledSortButtonBox,
} from "./styled";
import AdCard from "../AdCardNew/AdCard";
import { Box, IconButton, Skeleton } from "@mui/material";
import { Button, StyledText } from "components/shared";
import { ReactComponent as SortIcon } from "../../../../assets/cai/svg/sort.svg";
import { PxSelect } from "components/CreativeAI_Revamp/shared";
import { AdsItemType } from "store/types/CreativeAI_Revamp/facebook.types";
import AdCardLoading from "../AdCardNew/AdCardLoading";
import TechnicalIssue from "../TechnicalIssue/TechnicalIssue";
import { useHomeLeftPanel } from "./hooks/useHomeLeftPanel";
import { useFilterHook } from "./hooks/useFilterHook";
import NoFilteredAds from "../NoFilterdAdsScreen/NoFilteredAds";
import { Fragment, useEffect, useState } from "react";
import { getCurrencySymbol } from "utils/commonFunctions";
import { useDispatch } from "react-redux";
import { setSelectedAd } from "store/actions/CreativeAI/Facebook/CreateVariant/createVariant";

export default function HomeScreenLeftPanel({
  handelAdsSelect,
  selectedAdIndex,
  setSelectedAdIndex,
  adsSortFilterLoading,
  setAdsSortFilterLoading,
  setFilteredAdLength,
}: {
  handelAdsSelect: (item: AdsItemType, index: number) => void;
  selectedAdIndex: number;
  setSelectedAdIndex: any;
  adsSortFilterLoading: boolean;
  setAdsSortFilterLoading: any;
  setFilteredAdLength: any;
}) {
  const {
    selectedAd,
    sortSelect,
    setSortSelect,
    sortType,
    setSortType,
    onSortClick,
    sortOption,
    selectedAds,
    adsOtherState,
    adAccountDetails,
    resultMetrics,
    adsPaginationCursor,
    onLoadMoreAdsClick
  } = useHomeLeftPanel({ selectedAdIndex });

  const { filteredAds } = useFilterHook({
    setSelectedAdIndex,
    sortType,
    sortSelect,
    setAdsSortFilterLoading,
    setFilteredAdLength,
  });
  const [isEmpty, setIsEmpty] = useState(false);
  const renderComponent = (value: any) => {
    return (
      <StyledText
        width="100%"
        sx={{
          textAlign: "center",
        }}
        fontSize="0.75em"
        fontWeight={400}
        lineHeight="20px"
      >
        {value}
      </StyledText>
    );
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      filteredAds &&
      filteredAds.length > 0 &&
      selectedAds &&
      selectedAds?.length > 0
    ) {
      const filteredSelectedAds = filteredAds.filter((item, index) => {
        return selectedAds.find((item2) => item2.id === item.id);
      });
      const selectedIndex = filteredAds.findIndex(
        (item) => item?.id === selectedAd?.id
      );
      if (selectedIndex > -1) {
        setSelectedAdIndex(selectedIndex);
      } else {
        const filterselectedFrrstAd = filteredAds.findIndex((item, index) => {
          return filteredSelectedAds?.[0]?.id == item?.id;
        });
        dispatch(setSelectedAd(filteredSelectedAds?.[0]));
        setSelectedAdIndex(filterselectedFrrstAd);
      }
      setFilteredAdLength(filteredSelectedAds.length);
      if (filteredSelectedAds.length === 0) {
        setIsEmpty(true);
      } else {
        setIsEmpty(false);
      }
    }
  }, [filteredAds, selectedAds]);
  const showAdsBody = () => {
    if (adsOtherState?.isLoading || adsSortFilterLoading) {
      return <AdCardLoading />;
    } else if (adsOtherState?.isFailed) {
      return <TechnicalIssue type="Ad" />;
    } else if (isEmpty || (filteredAds && filteredAds?.length === 0)) {
      return <NoFilteredAds type="Ad" />;
    } else {
      return (
        filteredAds &&
        filteredAds?.length > 0 &&
        filteredAds?.map((item, index) => {
          const isSelected = selectedAds.find((item2) => item2.id === item.id);
          return isSelected ? (
            <AdCard
              item={item}
              activeAd={selectedAdIndex === index}
              index={index}
              previewLoading=""
              adPreviewLink=""
              handelAdsSelect={handelAdsSelect}
              currency={
                adAccountDetails
                  ? getCurrencySymbol(adAccountDetails?.currency)
                  : ""
              }
            />
          ) : (
            <Fragment />
          );
        })
      );
    }
  };
  return (
    <StyledHomeScreenLeftBox>
      <Box
        display="flex"
        justifyContent="space-between"
        p="1em 1.125em 1em 1.125em"
        width="100%"
        alignItems="center"
      >
        <StyledText fontSize="0.875em" lineHeight="20px">
          Select Reference Ad:
        </StyledText>
        {adsOtherState?.isLoading ? (
          <Skeleton
            sx={{
              height: "1.8em",
              width: "7em",
              borderRadius: "6px",
            }}
            variant="rectangular"
          />
        ) : (
          <Box display="flex">
            <StyledSortButtonBox>
              <IconButton onClick={onSortClick} className={sortType}>
                <SortIcon />
              </IconButton>
            </StyledSortButtonBox>
            <PxSelect
              MenuProps={{
                PaperProps: {
                  sx: {
                    width: "4.8em",
                  },
                },
              }}
              sx={{
                borderWidth: "1px 1px 1px 0px",
                borderStyle: "solid",
                borderColor: "#E5E5E5",
                borderRadius: "0px 6px 6px 0px",
                fontSize: "1em",
              }}
              options={sortOption}
              hideSubtitle
              selection={{ value: sortSelect }}
              handlePropChange={(value) => {
                setSortSelect(value?.value);
              }}
              renderComponent={renderComponent}
              dropDownContainerWidth="4.8em"
              dropDownContainerHeight="1.75em"
              hideLeading
              hideTooltip
            />
          </Box>
        )}
      </Box>
      <StyledHomeScreenLeftScrollBox gap=".8em" mb="2.5em">
        {showAdsBody()}
        {adsPaginationCursor?.after && <Button
          variant="contained"
          disabled={adsOtherState.isFetching}
          sx={{
            height: "2.8em",
            background: "#0869FB",
            borderRadius: "0.5em",
            fontSize: "0.875em",
            fontWeight: 600,
            lineHeight: "1.125em",
            color: "#fffff",
            padding: "0.5em 1.3125em 0.5em 1em",
            boxShadow: "none",
          }}
          onClick={() => {
            onLoadMoreAdsClick();
          }}
        >
          Load More
        </Button>}
        {/* <NoFilteredAds type="Ad" /> */}
      </StyledHomeScreenLeftScrollBox>
      <StyledFooterBox>
        {"*Showing lifetime Ad performance data "}
        <br />
        {resultMetrics?.isLoading ? (
          <Skeleton
            sx={{
              height: "2em",
              width: "20em",
            }}
          />
        ) : (
          `${
            resultMetrics?.data?.label?.length > 0
              ? "*CPR is Cost Per " +
                resultMetrics?.data?.label +
                (resultMetrics?.data?.attributionWindow?.length > 0 &&
                resultMetrics?.data?.attributionWindow !== "default" &&
                resultMetrics?.data?.isAttribuationMetric === false
                  ? " with Attribution window as " +
                    resultMetrics?.data?.attributionWindow
                  : "")
              : "*CPR is not defined yet"
          }`
        )}
      </StyledFooterBox>
    </StyledHomeScreenLeftBox>
  );
}
