import { Box, Skeleton } from "@mui/material";
import { AlertDialog, Button, StyledText } from "components/shared";
import { useMemo, useState } from "react";
import {
  StyledStatusWrapper,
  StyledCheckStatusButton,
  StyledHomeScreenRightBox,
  StyledHomeScreenRightBoxHeader,
  StyledHomeScreenRightBoxHeaderInfo,
  StyledInProgressLoader,
  StyledVariantInfoBox,
} from "./styled";

import { ReactComponent as Add } from "../../../../assets/cai/svg/add_white.svg";
import InProgressLoader from "../../../../assets/cai/gif/autogenerate_inprogress.gif";
import { TextWithTooltip } from "components/CreativeAI_Revamp/shared";
import OpenCAISetup from "../OpenCAISetup/OpenCAISetup";
import LoadingVariants from "./VariantCard/LoadingVariants";
import PreferredMethodModal from "./PreferredMethodModal/PreferredMethodModal";
import AutogenerateLoader from "./AutogenerateLoader/AutogenerateLoader";
import CTAFloater from "./CTAFloater/CTAFloater";
import { FacebookInitialState } from "store/types/CreativeAI_Revamp/facebook.types";
import { useDispatch, useSelector } from "react-redux";
import VariantListing from "./VariantCard/VariantListing";
import { useHomeRightPanel } from "./hooks/useHomeRightPanel";
import { checkSupportedAdType } from "../utils/common.utils";
import AdNotSupportedScreen from "../AdNotSupportedScreen/AdNotSupportedScreen";
import NoFilteredAds from "../NoFilterdAdsScreen/NoFilteredAds";
import { useAutoGenerate } from "./hooks/useAutoGenerate";
import { getAds } from "store/actions/CreativeAI/Facebook/CreateVariant/createVariant";
import { useCreativeAnimator } from "./hooks/useCreativeAnimator";
import { CustomizedSnackBars } from "components/shared/AlertSnackBar/AlertSnackBar";
import ReviewModal from "../ReviewModals/ReviewModal";
import ReviewFlowModal from "../ReviewFlowModal/ReviewFlowModal";
import { checkAdType } from "../utils/common.utils_old";
import { AdAccountState } from "store/types/adaccount";
import { InitialMediaStudioType } from "store/types/CreativeAI/Facebook/CreateVariant/mediaStudio.types";
import { useCaiFbMixPanel } from "./hooks/useCaiFbMixPanel";
import OnBoardingScreen from "./OnBoardingScreen/OnBoardingScreen";
import { useUTG } from "./hooks/useUTG";
import { FETCH_CREATIVE_ADS_PAGINATION_LIMIT } from "../utils/const";

export default function HomeScreenRightPanel({
  adsSortFilterLoading,
}: {
  adsSortFilterLoading: boolean;
}) {
  // local states
  const [openCreateVariantModal, setOpenCreateVariantModal] = useState(false);
  const [openAutoGenerateModal, setOpenAutoGenerateModal] = useState(false);
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [openReviewFlowModal, setOpenReviewFlowModal] = useState(false);

  // redux state
  const {
    aiGroupList: { selectedAIGroup },
  } = useSelector(
    (state: { facebookReducer: FacebookInitialState }) => state.facebookReducer
  );
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { selectedBrand } = adaccount;

  const { getMediaStudioData } = useSelector(
    (state: { mediaStudioReducer: InitialMediaStudioType }) =>
      state.mediaStudioReducer
  );
  const { elementCreativeAi, fetchingBusinessUnit } = useSelector(
    (state: { MainReducer: any }) => state.MainReducer
  );
  const { generateUTGRecommendations, utgData } = useUTG();
  // hooks
  const {
    fetchingVariants,
    filteredVariantList,
    createSpec,
    variantList,
    selectedAd,
    totalSelectedVariant,
    ads,
    adSetSelection,
    adAccountsSelection,
    adsOtherState,
    filteredVariantLoading,
    adsetDetails,
  } = useHomeRightPanel();
  const {
    isFailed,
    isLoading,
    onClickCreateAnimater,
    setIsLoading,
    onCloseCreateAnimater,
    adVariants,
    resetFlag,
  } = useCreativeAnimator();
  const {
    generateCAIRecommendationsBG,
    autogenerate,
    // hasAutoTriggered,
  } = useAutoGenerate();

  const {
    mixPanelCreateVariantsClicked,
    mixPanelReview,
    mixPanelPublish,
    mixPanelReviewLinkCopied,
  } = useCaiFbMixPanel();
  // local variable and functions

  const isAdSupported = useMemo(
    () => (createSpec ? checkSupportedAdType(createSpec).isSupported : false),
    [createSpec]
  );
  const dispatch = useDispatch();

  const refreshAds = () => {
    if (
      adSetSelection &&
      adsetDetails?.adset?.adsetId == adSetSelection?.selectedAdSetId
    ) {
      dispatch(
        getAds({
          adSetId: adSetSelection?.selectedAdSetId,
          adAccountId: adAccountsSelection?.selectedAdAccountId,
          adsCount: FETCH_CREATIVE_ADS_PAGINATION_LIMIT,
        })
      );
    }
  };
  const selectedAdIndex = ads.findIndex(
    (item: any) => item?.id === selectedAd?.id
  );
  const showVariantBody = () => {
    if (
      fetchingVariants ||
      adsOtherState.isLoading ||
      adsSortFilterLoading ||
      filteredVariantLoading
    ) {
      return <LoadingVariants />;
    } else if (
      !selectedAIGroup?.Elements?.[0]?.ElementCreativeAiData?.[0]?.id
    ) {
      return <OpenCAISetup />;
    } else if (
      !selectedAd?.hasAutogenerateTriggered &&
      variantList?.length == 0 &&
      (!utgData?.[selectedAd?.id]?.status?.inProgress ||
        !autogenerate?.[selectedAd?.id]?.status?.inProgress)
    ) {
      return (
        <OnBoardingScreen
          generateUTGRecommendations={generateUTGRecommendations}
          openCreateVariantModal={openCreateVariantModal}
          setOpenCreateVariantModal={setOpenCreateVariantModal}
          generateCAIRecommendations={generateCAIRecommendationsBG}
          setOpenAutoGenerateModal={setOpenAutoGenerateModal}
          onClickCreateAnimater={onClickCreateAnimater}
          billboardDisabled={
            !selectedBrand?.isCreativeAnimator
              ? 1
              : createSpec && checkAdType(createSpec) === "video_data"
              ? 2
              : 0
          }
          autogenerateDisabled={
            autogenerate?.[selectedAd?.id]?.status?.inProgress ||
            utgData?.[selectedAd?.id]?.status?.inProgress
          }
          adId={selectedAd?.id}
          utgDisabled={
            autogenerate?.[selectedAd?.id]?.status?.inProgress ||
            utgData?.[selectedAd?.id]?.status?.inProgress
          }
        />
      );
    } else if (
      variantList?.length > 0 &&
      filteredVariantList?.length == 0 &&
      !filteredVariantLoading
    ) {
      return <NoFilteredAds type="Variant" />;
    } else if (!isAdSupported) {
      return <AdNotSupportedScreen />;
    } else {
      return <VariantListing />;
    }
  };

  const showVariantButton = () => {
    if (
      adsOtherState.isLoading ||
      getMediaStudioData?.isLoading ||
      elementCreativeAi.loading ||
      fetchingBusinessUnit
    ) {
      return (
        <Skeleton
          sx={{
            height: "2.4em",
            width: "8.5em",
            borderRadius: "0.5em",
          }}
          variant="rectangular"
        />
      );
    } else if (
      selectedAIGroup?.Elements?.[0]?.ElementCreativeAiData?.[0]?.id &&
      isAdSupported
    ) {
      return (
        <Button
          variant="contained"
          sx={{
            height: "2.8em",
            background: "#0869FB",
            borderRadius: "0.5em",
            fontSize: "0.875em",
            fontWeight: 600,
            lineHeight: "1.125em",
            color: "#fffff",
            padding: "0.5em 1.3125em 0.5em 1em",
            boxShadow: "none",
          }}
          startIcon={<Add />}
          onClick={() => {
            setOpenCreateVariantModal(true);
            mixPanelCreateVariantsClicked();
          }}
        >
          Create Variants
        </Button>
      );
    }
  };

  return (
    <StyledHomeScreenRightBox>
      <StyledHomeScreenRightBoxHeader>
        <StyledHomeScreenRightBoxHeaderInfo>
          <Box display="flex" minWidth="0" alignItems="center">
            <StyledText
              color="#333333"
              fontWeight={600}
              pr=".25em"
              lineHeight="1.0625em"
            >
              Ad Name:
            </StyledText>
            <Box display="flex" maxWidth="100%">
              {" "}
              <TextWithTooltip
                color="#333333"
                truncateLength={50}
                text={selectedAd?.name}
              />
            </Box>
          </Box>
          <StyledVariantInfoBox>
            <StyledText
              color="#333333"
              fontWeight={600}
              pr=".35em"
              lineHeight="1em"
              fontSize="0.75em"
            >
              Variant Info :
            </StyledText>

            <VariantInfoRow
              info="In Draft"
              count={ads[selectedAdIndex]?.draftVariants ?? "--"}
              color="#B884F9"
              isLoading={adsOtherState.isLoading}
            />
            <VariantInfoRow
              info="Under Review"
              count={ads[selectedAdIndex]?.underReviewVariants ?? "--"}
              color="#F8862D"
              isLoading={adsOtherState.isLoading}
            />
            <VariantInfoRow
              info="Published"
              count={ads[selectedAdIndex]?.publishedVariants ?? "--"}
              color="#12C8B2"
              isLoading={adsOtherState.isLoading}
              isLast
            />
          </StyledVariantInfoBox>
        </StyledHomeScreenRightBoxHeaderInfo>
        {showVariantButton()}
      </StyledHomeScreenRightBoxHeader>
      {(!autogenerate?.[selectedAd?.id]?.status?.auto &&
        autogenerate?.[selectedAd?.id]?.status?.inProgress) ||
      autogenerate?.[selectedAd?.id]?.status?.isError ||
      utgData?.[selectedAd?.id]?.status?.inProgress ||
      utgData?.[selectedAd?.id]?.status?.isError ? (
        <StyledStatusWrapper>
          <StyledInProgressLoader src={InProgressLoader} />
          <StyledText fontSize="0.78125em" color="#444444" fontWeight={400}>
            {autogenerate?.[selectedAd?.id]?.status?.inProgress ||
            utgData?.[selectedAd?.id]?.status?.inProgress ? (
              <>
                Auto generation of variants is in progress.
                <StyledCheckStatusButton
                  onClick={() => setOpenAutoGenerateModal(true)}
                >
                  Click here
                </StyledCheckStatusButton>{" "}
                to check the status
              </>
            ) : (
              (autogenerate?.[selectedAd?.id]?.status?.isError ||
                utgData?.[selectedAd?.id]?.status?.isError) &&
              "Autogeneration of variants failed, kindly retry!"
            )}
          </StyledText>
        </StyledStatusWrapper>
      ) : null}
      {showVariantBody()}
      <PreferredMethodModal
        billboardDisabled={
          !selectedBrand?.isCreativeAnimator
            ? 1
            : createSpec && checkAdType(createSpec) === "video_data"
            ? 2
            : 0
        }
        autogenerateDisabled={
          autogenerate?.[selectedAd?.id]?.status?.inProgress ||
          utgData?.[selectedAd?.id]?.status?.inProgress
        }
        utgDisabled={
          autogenerate?.[selectedAd?.id]?.status?.inProgress ||
          utgData?.[selectedAd?.id]?.status?.inProgress
        }
        openCreateVariantModal={openCreateVariantModal}
        setOpenCreateVariantModal={setOpenCreateVariantModal}
        generateCAIRecommendations={generateCAIRecommendationsBG}
        generateUTGRecommendations={generateUTGRecommendations}
        setOpenAutoGenerateModal={setOpenAutoGenerateModal}
        onClickCreateAnimater={onClickCreateAnimater}
        adId={selectedAd?.id}
      />
      {openAutoGenerateModal &&
        (autogenerate?.[selectedAd?.id]?.status?.inProgress ||
          utgData?.[selectedAd?.id]?.status?.inProgress) && (
          <AutogenerateLoader
            openAutoGenerateModal={openAutoGenerateModal}
            setOpenAutoGenerateModal={setOpenAutoGenerateModal}
            adId={selectedAd?.id}
          />
        )}
      {isLoading && (
        <AlertDialog
          show={isLoading}
          title="You will be redirected to a new tab in a while. Click Cancel to stay in the same screen."
          confirmText="Cancel"
          onComplete={onCloseCreateAnimater}
          onClose={() => setIsLoading(false)}
          alignMent="flex-end"
          isTopAlign
        />
      )}
      <CustomizedSnackBars
        handleClose={resetFlag}
        open={isFailed}
        severity="error"
        msg="An error occurred during generating a link"
      />
      {totalSelectedVariant?.length > 0 && (
        <CTAFloater
          noOfVariantsSelected={totalSelectedVariant}
          setOpenReviewModal={(val: boolean) => setOpenReviewModal(val)}
          refreshAds={refreshAds}
          mixPanelReview={mixPanelReview}
          setOpenReviewFlow={setOpenReviewFlowModal}
        />
      )}
      {openReviewModal && (
        <ReviewModal
          mixPanelReview={mixPanelReview}
          mixPanelPublish={mixPanelPublish}
          show={openReviewModal}
          setOpenReviewFlow={(val: boolean) => setOpenReviewFlowModal(val)}
          onHide={() => setOpenReviewModal(false)}
          refreshAds={() => {
            refreshAds();
          }}
        />
      )}
      {openReviewFlowModal && (
        <ReviewFlowModal
          mixPanelReviewLinkCopied={mixPanelReviewLinkCopied}
          show={openReviewFlowModal}
          platform="facebook"
          onClose={() => setOpenReviewFlowModal(false)}
        />
      )}
      <CustomizedSnackBars
        handleClose={resetFlag}
        open={isFailed}
        severity="error"
        msg="An error occurred during generating a link"
      />
      {/* <ConfirmDialog
        show={showErrorPopUp && (isfechingBillboardError || billboardError)}
        onClose={() => {
          setShowErrorPopUp(false);
          setIsFetchingBillboardError(false);
          setOpenAutoGenerateModal(false);
          setBillBoardError(false);
        }}
        title="Oops! Not able to auto-generate the variants at the moment. Please try again."
        onComplete={() => generateCAIRecommendationsBG("Manual")}
        completeButtonText="Retry"
      /> */}
    </StyledHomeScreenRightBox>
  );
}

const VariantInfoRow = ({
  info,
  count,
  color,
  isLast,
  isLoading,
}: {
  info: string;
  count: string;
  color: string;
  isLast?: boolean;
  isLoading?: boolean;
}) => {
  return (
    <Box display="flex" alignItems="center">
      <StyledText
        fontSize="0.75em"
        fontWeight={400}
        lineHeight="1em"
        pr=".35em"
      >
        {info}
      </StyledText>
      {isLoading ? (
        <Skeleton
          width="1.3em"
          sx={{
            fontSize: "0.75em",
            lineHeight: "1.4em",
            fontWeight: 400,
          }}
        />
      ) : (
        <StyledText
          fontSize="0.75em"
          fontWeight={400}
          lineHeight="1em"
          color={color}
        >
          {count}
        </StyledText>
      )}

      {!isLast && (
        <StyledText
          fontSize="0.75em"
          fontWeight={400}
          lineHeight="1em"
          color="#C8C8C8"
          p="0px 0.625em"
        >
          |
        </StyledText>
      )}
    </Box>
  );
};
