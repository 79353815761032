import { cloneDeep, orderBy } from "lodash";
import {
  AddUpdateVariantLoadingState,
  AddVarianButton,
  AddVariant,
  ADD_UPDATE_VARIANT_LOADING_STATE,
  ADD_VARIANT,
  ADD_VARIANT_BUTTON,
  CopyVariant,
  COPY_VARIANT,
  COPY_VARIANT_STATE,
  CreateVariantsState,
  DeleteVariantAPICall,
  DeleteVariantStateUpdate,
  DELETE_VARIANT_API_CALL,
  DELETE_VARIANT_STATE_UPDATE,
  GenerateVariantMatadata,
  GENERATE_VARIANT_METADATA,
  DiscardDraftVariant,
  DiscardDraftVariantState,
  DISCARD_DRAFT_VARIANT,
  DISCARD_DRAFT_VARIANT_STATE,
  GetAdsListValues,
  GET_ADS_LIST,
  PublishVariantState,
  PUBLISH_VARIANT_STATE,
  Is_Show_SaveVariant_Prompt,
  ResetState,
  RESET_STATE,
  SetAddVariantResponse,
  SetAdPreviewLink,
  SetAds,
  SetCreateSpec,
  SetRecommendationMetadata,
  SetSelectedAds,
  SetVariantList,
  SET_ADD_VARIANT_RESPONSE,
  SET_ADS,
  SET_AD_PREVIEW_LINK,
  SET_CREATE_SPEC,
  SET_RECOMMENDATION_LOADING,
  SET_RECOMMENDATION_METADATA,
  SET_VARIANT_LIST,
  SET__SELECTED_ADS,
  UpdateAds,
  UpdateCreateSpec,
  UpdateExtraField,
  UpdateRecommendationMetadata,
  UpdateRecommendationSelectionState,
  UpdateTotalVariantSelected,
  UpdateVariant,
  UpdateVariantState,
  UPDATE_ADS,
  UPDATE_CREATE_SPEC,
  UPDATE_EXTRA_FIELD,
  UPDATE_RECOMMENDATION_METADATA,
  UPDATE_RECOMMENDATION_SELECTION_STATE,
  UPDATE_TOTAL_VARIANT_SELECTED,
  UPDATE_VARIANT,
  UPDATE_VARIANT_STATE,
  GENERATE_VARIANT_METADATA_STATE,
  IS_CONFIGURING_STATE,
  UPDATE_AI_GROUP,
  UPDATE_AI_GROUP_STATE,
  SET_UPDATE_AI_GROUP_PROMPT,
  GET_VARIANT_LIST,
  GET_CREATE_SPEC,
  GENERATE_VARIANT_METADATA_STATE_VARIANT,
  SET_RECOMMENDATION_METADATA_ERROR,
  SET_TIMESTAMP,
  UPDATE_CONFIGURE_STATE,
  GENERATE_AD_PREVIEW,
  GENERATE_AD_PREVIEW_VARIANT,
  GENERATE_AD_PREVIEW_VARIANT_STATE,
  SET_CHANGED_FIELD_LIST,
  GENERATE_AD_PREVIEW_VARIANT_LOADING_STATE,
  SET_AUTOGENERATE_LOADING,
  SetAutoGenerateLoading,
  SET_LOADING_COMPLETE,
  SET_FETCHING_COMPLETE,
  SUBMIT_FOR_REVIEW_STATE,
  APPROVER_LINK,
  SUBMIT_FOR_REVIEW_ERROR,
  RE_PUBLISH_VARIANT_STATE,
  DELETE_SELECTED_VARIANT,
  SET_FILTERED_VARIANT_LIST,
  SetFilteredVariants,
  UNSELECT_TOTAL_VARIANTS,
  SetUnselectTotalVariants,
  SET_DELETE_SELECTED_VARIANT,
  SetDeleteSelectedVariants,
  SET_ADS_OTHER_STATE,
  SET_AUTOGENERATE_RESPONSE,
  SetVariantsNewTag,
  SET_VARIANTS_NEW_TAGS,
  SET_FILTERED_VARIANTS_LOADING,
} from "../../../../types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import { FETCH_CREATIVE_ADS_PAGINATION_LIMIT } from "components/CreativeAI_Revamp/Facebook/utils/const";

export const initialStateCreateVariant: CreateVariantsState | any = {
  adsList: [],
  variantList: [],
  filteredVariantList: [],
  recommendationMetaData: {},
  recommendationMetaDataTemplate: [],
  addVariantResponse: undefined,
  createSpec: undefined,
  ads: [],
  adsPaginationCursor: undefined,
  selectedAd: undefined,
  adPreviewLink: undefined,
  isUpdatingVariant: false,
  isDeletingVariant: false,
  isDiscardingDraft: false,
  adsOtherState: {
    isFetching: false, // this is when async call is in progress
    isLoading: true,
    isFailed: false,
  },
  isUpdateAds: false,
  loadingRecommendation: true,
  isGeneratingPreview: false,
  extraFields: {
    isVariantEditing: undefined,
    selectedVariantIndex: undefined,
    showEditor: undefined,
    isCloned: undefined,
  },
  isConfiguringMetaData: false,
  isGeneratingMetaData: false,
  addUpdateVariantLoading: false,
  totalSelectedVariant: [],
  isPublishingVariant: false,
  isRePublishingVariant: false,
  isSubmittingReviewVariant: false,
  approverLink: { isLoading: false, isError: false, isData: "" },
  submitReviewError: false,
  isUpdatingAIgroup: false,
  generateAdPreviewVariantLoading: false,
  recommendationSelectionState: {
    primary_text: "",
    headline: "",
    image: "",
    description: "",
    adPreviewLink: "",
    variantName: "",
    isChanged: false,
    isConfigureChanged: false,
  },
  filteredVariantLoading: true,
  generateMetaData: {},
  isShowSaveVariantPrompt: false,
  showUpdateAISuccessMsg: false,
  fetchingVariants: false,
  fetchingCreateSpec: false,
  executionIdVariant: undefined,
  generateRecommendationError: false,
  generateRecommendationTimestamp: undefined,
  configureMetaData: undefined,
  changedFieldList: [],
  autoGenerateLoading: false,
  loadingComplete: false,
  fetchingComplete: false,
  isDeletingMultipleVariant: false,
};

const createVarianReducer = (
  state = initialStateCreateVariant,
  action:
    | GetAdsListValues
    | SetVariantList
    | SetRecommendationMetadata
    | UpdateRecommendationMetadata
    | SetAddVariantResponse
    | SetCreateSpec
    | SetAds
    | SetSelectedAds
    | SetAdPreviewLink
    | DeleteVariantStateUpdate
    | UpdateExtraField
    | AddUpdateVariantLoadingState
    | UpdateRecommendationSelectionState
    | UpdateVariantState
    | CopyVariant
    | UpdateAds
    | UpdateTotalVariantSelected
    | PublishVariantState
    | GenerateVariantMatadata
    | ResetState
    | UpdateCreateSpec
    | UpdateVariant
    | DeleteVariantAPICall
    | AddVariant
    | DiscardDraftVariant
    | DiscardDraftVariantState
    | AddVarianButton
    | SetAutoGenerateLoading
    | SetFilteredVariants
    | SetUnselectTotalVariants
    | SetDeleteSelectedVariants
    | SetVariantsNewTag
    | any
) => {
  switch (action.type) {
    case GET_ADS_LIST: {
      return {
        ...state,
        adsOtherState: {
          isLoading: true,
          isFailed: false,
        },
      };
    }
    case GET_VARIANT_LIST: {
      return {
        ...state,
        fetchingVariants: true,
      };
    }

    case SET_VARIANT_LIST: {
      return {
        ...state,
        variantList: action.payload.data,
        fetchingVariants: false,
      };
    }

    case SET_TIMESTAMP: {
      return {
        ...state,
        generateRecommendationTimestamp: +new Date(),
        generateRecommendationError: false,
      };
    }
    case SET_RECOMMENDATION_METADATA_ERROR: {
      return {
        ...state,
        generateRecommendationError: action.payload,
      };
    }

    case RESET_STATE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case UPDATE_VARIANT: {
      return {
        ...state,
        isUpdatingVariant: true,
      };
    }

    case GENERATE_AD_PREVIEW: {
      return {
        ...state,
        isGeneratingPreview: true,
      };
    }
    case GENERATE_AD_PREVIEW_VARIANT_LOADING_STATE: {
      return {
        ...state,
        isGeneratingPreview: action.payload,
      };
    }

    case ADD_VARIANT: {
      return {
        ...state,
        isUpdatingVariant: true,
      };
    }

    case SET_RECOMMENDATION_LOADING: {
      return {
        ...state,
        loadingRecommendation: action.payload,
      };
    }
    case COPY_VARIANT: {
      return {
        ...state,
        extraFields: { ...state.extraFields, isCloned: true },
        addUpdateVariantLoading: true,
      };
    }

    case UPDATE_AI_GROUP: {
      return {
        ...state,
        isUpdatingAIgroup: true,
      };
    }

    case UPDATE_AI_GROUP_STATE: {
      return {
        ...state,
        isUpdatingAIgroup: false,
        showUpdateAISuccessMsg: action.payload,
      };
    }

    case SET_UPDATE_AI_GROUP_PROMPT: {
      return {
        ...state,
        showUpdateAISuccessMsg: action.payload,
      };
    }

    case SET_RECOMMENDATION_METADATA: {
      return {
        ...state,
        recommendationMetaDataTemplate: action.payload.data,
      };
    }
    case UPDATE_RECOMMENDATION_METADATA: {
      return {
        ...state,
        recommendationMetaData: action.payload.data,
        configureMetaData: action.payload?.configureMetaData,
        loadingRecommendation: false,
      };
    }
    case DISCARD_DRAFT_VARIANT: {
      return {
        ...state,
        isDiscardingDraft: true,
      };
    }

    case DISCARD_DRAFT_VARIANT_STATE: {
      return {
        ...state,
        isDiscardingDraft: action.payload.data,
      };
    }

    case UPDATE_CONFIGURE_STATE: {
      return {
        ...state,
        configureMetaData: action.payload,
      };
    }

    case SET_ADD_VARIANT_RESPONSE: {
      let ads = state.ads;
      const index = ads.findIndex(
        (item: any) => item.id === state.selectedAd.id
      );
      ads[index].draftVariants = ads[index].draftVariants + 1;
      if (action.payload?.CreativeAiData?.billboardMediaId) {
        ads[index].billboardVariants = ads[index].billboardVariants + 1;
      }
      const variantList = state.variantList.filter(
        (element: any) => element.id !== null
      );
      return {
        ...state,
        variantList: [...variantList, action.payload],
        isUpdatingVariant: false,
        ads,
        recommendationSelectionState: {
          ...state.recommendationSelectionState,
          isChanged: false,
          isConfigureChanged: false,
        },
      };
    }
    case SET_AUTOGENERATE_RESPONSE: {
      let ads = state.ads;
      const index = ads.findIndex(
        (item: any) => item.id === action.payload?.adId
      );
      const isSelectedAd = action.payload?.adId === state.selectedAd.id;
      let variantList = [];
      if (index >= 0) {
        ads[index].draftVariants = ads[index].draftVariants + 1;
        if (action.payload?.CreativeAiData?.billboardMediaId) {
          ads[index].billboardVariants = ads[index].billboardVariants + 1;
        }
      }
      if (isSelectedAd) {
        variantList = state.variantList.filter(
          (element: any) => element.id !== null
        );
        variantList.push(action.payload);
      } else if (index >= 0 && ads[index].variantList) {
        ads[index].variantList = [...ads[index].variantList, action.payload];
      }
      return {
        ...state,
        variantList:
          action.payload?.adId === state.selectedAd.id
            ? [...variantList]
            : state.variantList,
        isUpdatingVariant: false,
        ads,
        recommendationSelectionState: {
          ...state.recommendationSelectionState,
          isChanged: false,
          isConfigureChanged: false,
        },
      };
    }
    case GET_CREATE_SPEC: {
      return {
        ...state,
        fetchingCreateSpec: true,
      };
    }
    case SET_CREATE_SPEC: {
      let createSpec = action.payload;
      if (createSpec?.object_story_spec?.video_data) {
        let video_data = createSpec?.object_story_spec?.video_data;
        delete video_data?.image_hash;
        createSpec = {
          ...createSpec,
          object_story_spec: {
            ...createSpec.object_story_spec,
            video_data,
          },
        };
      }
      return {
        ...state,
        // createSpec: action.payload?.adcreatives?.data[0],
        createSpec,
        fetchingCreateSpec: false,
      };
    }
    case ADD_VARIANT_BUTTON: {
      let selectedVariantIndex = state.extraFields.selectedVariantIndex;
      selectedVariantIndex = state.variantList.length;
      return {
        ...state,
        variantList: [...state.variantList, { id: null }],
        // executionIdVariant: state.generateMetaData?.executionId,
        extraFields: {
          ...state.extraFields,
          selectedVariantIndex,
          showEditor: true,
        },
      };
    }
    case SET_ADS: {
      return {
        ...state,
        ads: state.adsOtherState.isFetching
          ? [...state.ads, ...(action.payload?.data ?? [])]
          : action.payload?.data ?? [],
        adsPaginationCursor:
          action.payload?.data?.length === FETCH_CREATIVE_ADS_PAGINATION_LIMIT
            ? action.payload?.paging?.cursors
            : undefined,
        selectedAd:
          [...state.ads, ...(action.payload?.data ?? [])].find(
            (item: any) => item?.id == state.selectedAd?.id
          ) ?? cloneDeep(action.payload?.data[0]),
        totalSelectedVariant: [],
      };
    }
    case SET_ADS_OTHER_STATE: {
      return { ...state, adsOtherState: action.payload };
    }
    case UPDATE_ADS: {
      return {
        ...state,
        ads: action.payload,
      };
    }
    case SET__SELECTED_ADS: {
      return {
        ...state,
        selectedAd: cloneDeep(action.payload),
      };
    }
    case SET_AD_PREVIEW_LINK: {
      return {
        ...state,
        adPreviewLink: action.payload,
      };
    }
    case DELETE_VARIANT_STATE_UPDATE: {
      let initState = {};
      let totalSelectedVariant = state.totalSelectedVariant;
      if (totalSelectedVariant.length) {
        totalSelectedVariant = totalSelectedVariant.filter(
          (item: any) => item !== action.payload.id
        );
      }
      let ads = state.ads;
      const index = ads.findIndex(
        (item: any) => item.id === state.selectedAd.id
      );
      ads[index].draftVariants = ads[index].draftVariants - 1;
      if (action.payload?.CreativeAiData?.billboardMediaId) {
        ads[index].billboardVariants = ads[index].billboardVariants - 1;
      }
      const newVariantList = state.variantList.filter(
        (element: any) => element.id !== action.payload.id
      );
      let selectedVariantIndex = state?.extraFields?.selectedVariantIndex;
      let showEditor = state?.extraFields?.showEditor;
      if (
        state?.extraFields?.selectedVariantIndex !== undefined &&
        newVariantList.length === state?.extraFields?.selectedVariantIndex
      ) {
        selectedVariantIndex = newVariantList.length
          ? state.extraFields.selectedVariantIndex - 1
          : undefined;
      }
      showEditor = state?.extraFields?.showEditor && !!newVariantList.length;
      if (!showEditor) {
        initState = {
          loadingRecommendation: true,
          isConfiguringMetaData: false,
          extraFields: {
            isVariantEditing: undefined,
            selectedVariantIndex: undefined,
            showEditor: undefined,
          },
          generateRecommendationError: false,
          generateRecommendationTimestamp: undefined,
          configureMetaData: undefined,
          recommendationMetaData: {},
          recommendationSelectionState: {
            primary_text: "",
            headline: "",
            description: "",
            adPreviewLink: "",
            variantName: "",
            isChanged: false,
            isConfigureChanged: false,
          },
        };
      }

      return {
        ...state,
        variantList: newVariantList,
        isDeletingVariant: false,
        totalSelectedVariant,
        recommendationMetaData:
          selectedVariantIndex !== undefined
            ? newVariantList[selectedVariantIndex].payload
                ?.recommendationMetaData
            : undefined,
        isConfiguringMetaData: showEditor ? state.isConfiguringMetaData : false,
        extraFields: {
          ...state?.extraFields,
          selectedVariantIndex,
          showEditor,
        },
        ads,
        ...initState,
      };
    }
    case UPDATE_EXTRA_FIELD: {
      return {
        ...state,
        extraFields: action.payload,
      };
    }
    case DELETE_VARIANT_API_CALL: {
      return {
        ...state,
        isDeletingVariant: true,
      };
    }
    case ADD_UPDATE_VARIANT_LOADING_STATE: {
      return {
        ...state,
        addUpdateVariantLoading: action.payload,
      };
    }
    case UPDATE_RECOMMENDATION_SELECTION_STATE: {
      return {
        ...state,
        recommendationSelectionState: {
          ...state.recommendationSelectionState,
          ...action.payload,
        },
      };
    }
    case COPY_VARIANT_STATE: {
      let ads = state.ads;
      const index = ads.findIndex(
        (item: any) => item.id === state.selectedAd.id
      );
      ads[index].draftVariants = ads[index].draftVariants + 1;
      const variantList = [...state.variantList, action.payload];
      let selectedVariantIndex = variantList.length - 1;

      return {
        ...state,
        variantList,
        addUpdateVariantLoading: false,
        ads,
        extraFields: {
          ...state.extraFields,
          selectedVariantIndex,
          isVariantEditing: false,
        },
      };
    }
    case UPDATE_VARIANT_STATE: {
      const variantList = [...state.variantList];
      variantList[action.payload.index] = action.payload.updatedValues;
      return {
        ...state,
        variantList,
        isConfiguringMetaData: false,
        isUpdatingVariant: false,
        recommendationSelectionState: {
          ...state.recommendationSelectionState,
          isChanged: false,
          isConfigureChanged: false,
        },
      };
    }
    case UNSELECT_TOTAL_VARIANTS: {
      // TBO
      let ads = state?.ads?.map((ad: any) => {
        let variantList = ad?.variantList?.map((variant: any) => {
          return { ...variant, checked: false };
        });
        return { ...ad, variantList };
      });
      return {
        ...state,
        totalSelectedVariant: [],
        ads,
      };
    }
    case UPDATE_TOTAL_VARIANT_SELECTED: {
      const id = action.payload;
      let totalSelectedVariant = [...state.totalSelectedVariant];
      if (totalSelectedVariant.includes(id)) {
        totalSelectedVariant = totalSelectedVariant.filter(
          (element: string) => element !== id
        );
      } else {
        totalSelectedVariant.push(id);
      }
      return {
        ...state,
        totalSelectedVariant,
      };
    }
    case PUBLISH_VARIANT_STATE: {
      return {
        ...state,
        isPublishingVariant: action.payload,
        totalSelectedVariant: [],
      };
    }
    case RE_PUBLISH_VARIANT_STATE: {
      return {
        ...state,
        isRePublishingVariant: action.payload,
      };
    }
    case SUBMIT_FOR_REVIEW_STATE: {
      return {
        ...state,
        approverLink: { ...state.approverLink, isLoading: action.payload },
      };
    }
    case APPROVER_LINK: {
      return {
        ...state,
        approverLink: { ...state.approverLink, isData: action.payload.urlHash },
        totalSelectedVariant: [],
      };
    }
    case SUBMIT_FOR_REVIEW_ERROR: {
      return {
        ...state,
        approverLink: { ...state.approverLink, isError: action.payload },
        totalSelectedVariant: action.payload
          ? [...state.totalSelectedVariant]
          : [],
      };
    }
    case UPDATE_CREATE_SPEC: {
      return {
        ...state,
        createSpec: action.payload,
      };
    }
    case GENERATE_VARIANT_METADATA: {
      return {
        ...state,
        isGeneratingMetaData: true,
      };
    }
    case GENERATE_VARIANT_METADATA_STATE: {
      let ads = state.ads;
      const index = ads.findIndex(
        (item: any) => item.id === state.selectedAd.id
      );

      ads[index] = { ...ads[index], executionId: action.payload?.executionId };
      return {
        ...state,
        generateMetaData: action.payload,
        isGeneratingMetaData: false,
        executionIdVariant: action.payload?.executionId,
        ads,
      };
    }

    case GENERATE_VARIANT_METADATA_STATE_VARIANT: {
      return {
        ...state,
        isGeneratingMetaData: false,
        executionIdVariant: action.payload?.executionId,
      };
    }
    case IS_CONFIGURING_STATE: {
      return {
        ...state,
        isConfiguringMetaData: action.payload,
      };
    }
    case Is_Show_SaveVariant_Prompt: {
      return {
        ...state,
        isShowSaveVariantPrompt: action.payload,
      };
    }

    case GENERATE_AD_PREVIEW_VARIANT: {
      return {
        ...state,
        generateAdPreviewVariantLoading: true,
      };
    }

    case GENERATE_AD_PREVIEW_VARIANT_STATE: {
      return {
        ...state,
        generateAdPreviewVariantLoading: false,
      };
    }
    case SET_CHANGED_FIELD_LIST: {
      return {
        ...state,
        changedFieldList: action.payload,
      };
    }
    case SET_AUTOGENERATE_LOADING: {
      return {
        ...state,
        autoGenerateLoading: action.payload,
      };
    }
    case SET_LOADING_COMPLETE: {
      return {
        ...state,
        loadingComplete: action.payload,
      };
    }

    case DELETE_SELECTED_VARIANT: {
      // need to handle it
      return {
        ...state,
        isDeletingMultipleVariant: true,
      };
    }
    case SET_FETCHING_COMPLETE: {
      return {
        ...state,
        fetchingComplete: action.payload,
      };
    }
    case SET_FILTERED_VARIANT_LIST: {
      return {
        ...state,
        // filteredVariantList: orderBy(
        //   action.payload,
        //   [
        //     (fv) =>
        //       fv.performanceScore?.status
        //         ? fv.performanceScore.data.performance_score
        //         : fv.performanceScore?.performance_score,
        //     'createdAt',
        //   ],
        //   ['desc', 'desc'],
        // ),
        filteredVariantList: action.payload,
        filteredVariantLoading: false,
      };
    }

    case SET_DELETE_SELECTED_VARIANT: {
      return {
        ...state,
        isDeletingMultipleVariant: false,
        totalSelectedVariant: [],
      };
    }
    case SET_VARIANTS_NEW_TAGS: {
      let ads = state.ads;
      let selectedAdIndex = ads.findIndex(
        (ad: any) => ad?.id == action.payload.adId
      );
      if (ads[selectedAdIndex]?.variantList) {
        ads[selectedAdIndex].variantList.forEach((variant: any) => {
          if (variant?.payload) {
            variant.payload.isNew = action.payload.isNew;
          }
        });
      }
      return {
        ...state,
        ads,
      };
    }
    case SET_FILTERED_VARIANTS_LOADING: {
      return {
        ...state,
        filteredVariantLoading: true,
      };
    }
    default:
      return state;
  }
};
export default createVarianReducer;
