import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAds,
  resetState,
  setAdsOtherState,
} from "store/actions/CreativeAI/Facebook/CreateVariant/createVariant";
import {
  setAdAccountsSelection,
  setAdAdditionalInfoReset,
  setAdSetSelection,
  setAdsSelection,
} from "store/actions/CreativeAI_Revamp/HomeScreen/homeScreen.actions";
import {
  getAdAccounts,
  getAdSetsFB,
  getAdsFB,
  setAdAccountDetails,
  setAdSetsFB,
  setAdsFB,
} from "store/actions/CreativeAI_Revamp/facebook.actions";
import {
  CreateVariantsState,
  SET_ADS,
} from "store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import { FBHomeScreenInitialStateType } from "store/types/CreativeAI_Revamp/HomeScreen/homeScreen.types";
import {
  AdSetItemType,
  AdsItemType,
  FacebookInitialState,
} from "store/types/CreativeAI_Revamp/facebook.types";
import { getAdAccountDetails } from "store/actions/adaccount";
import { FETCH_CREATIVE_ADS_PAGINATION_LIMIT } from "../../utils/const";
import { collator } from "utils/strings";

export const useHomeScreen = () => {
  const { adAccountsSelection, adSetSelection, adsSelection } = useSelector(
    (state: { fbHomeScreenReducer: FBHomeScreenInitialStateType }) =>
      state.fbHomeScreenReducer
  );
  const { aiGroupList, adAccountList, adSetList, parentPlatformId } =
    useSelector(
      (state: { facebookReducer: FacebookInitialState }) =>
        state.facebookReducer
    );

  const { adsetDetails } = useSelector(
    (state: { caiFBReducer: any }) => state.caiFBReducer
  );

  const { ads, adsOtherState, selectedAd } = useSelector(
    (state: { createVariantReducer: CreateVariantsState | any }) =>
      state.createVariantReducer
  );
  const dispatch = useDispatch();

  //ad accounts

  useEffect(() => {
    if (aiGroupList.selectedAIGroup && parentPlatformId) {
      dispatch(
        getAdAccounts({
          aiGroupId: aiGroupList.selectedAIGroup.id,
          params: {
            responseFields: "name,adAccountId,parentPlatformId",
            parentPlatformId,
          },
        })
      );
    }
  }, [aiGroupList.selectedAIGroup]);

  useEffect(() => {
    if (adAccountList.data.length > 0) {
      setAdAccountSelection(adAccountList.data[0]);
    } else {
      dispatch(
        setAdAccountsSelection({
          selection: undefined,
          selectedAdAccountId: "",
          selectedElementId: "",
        })
      );
    }

    // need to think about dependency, I don't want to reset selection after fetching more adaccount
  }, [adAccountList.data]);

  const setAdAccountSelection = (value: any) => {
    dispatch(
      setAdAccountsSelection({
        selection: value,
        selectedAdAccountId: value.adAccountId,
        selectedElementId: value.id,
      })
    );
  };

  //adsets

  useEffect(() => {
    if (adSetList.data.length > 0) {
      setAdsetSelection(adSetList.data[0]);
    } else {
      dispatch(
        setAdSetSelection({
          selectedAdSetId: "",
        })
      );
    }

    // need to think about dependency, I don't want to reset selection after fetching more adsets
  }, [adSetList.data]);

  useEffect(() => {
    if (adAccountsSelection?.selectedAdAccountId) {
      dispatch(
        getAdSetsFB({
          elementId: adAccountsSelection?.selectedElementId,
          params: {
            responseFields: "id,name",
            limit: 600,
          },
        })
      );
      dispatch(
        getAdAccountDetails(
          {
            adaccountId: adAccountsSelection?.selectedAdAccountId,
            platformType: "facebook",
          },
          (data: any, error: boolean) => {
            if (!error) {
              dispatch(setAdAccountDetails(data?.data));
            }
          }
        )
      );
    } else {
      dispatch(
        setAdSetsFB({
          isFailed: false,
          isLoading: true,
          data: [],
        })
      );
    }
  }, [adAccountsSelection?.selectedAdAccountId]);

  const setAdsetSelection = (value: AdSetItemType) => {
    dispatch(
      setAdSetSelection({
        selection: value,
        selectedAdSetId: value.adsetId,
      })
    );
  };

  // ads

  useEffect(() => {
    if (
      adSetSelection?.selectedAdSetId &&
      adAccountsSelection?.selectedAdAccountId &&
      adsetDetails?.adset?.adsetId == adSetSelection?.selectedAdSetId
    ) {
      // to reset the additional info
      dispatch(setAdAdditionalInfoReset({}));

      dispatch(
        getAds({
          adSetId: adSetSelection?.selectedAdSetId,
          adAccountId: adAccountsSelection?.selectedAdAccountId,
          adsCount: FETCH_CREATIVE_ADS_PAGINATION_LIMIT
        })
      );
    } else {
      dispatch(
        setAdsOtherState({
          isFetching: false,
          isLoading: true,
          isFailed: false,
        })
      );
      dispatch({ type: SET_ADS, payload: {data: []} });
      setAdSelection([]);

      let initState: any = {
        loadingRecommendation: true,
        isConfiguringMetaData: false,
        executionIdVariant: undefined,
        extraFields: {
          isVariantEditing: undefined,
          selectedVariantIndex: undefined,
          showEditor: undefined,
        },
        generateRecommendationError: false,
        generateRecommendationTimestamp: undefined,
        configureMetaData: undefined,
        recommendationMetaData: {},
        recommendationSelectionState: {
          primary_text: "",
          headline: "",
          description: "",
          adPreviewLink: "",
          variantName: "",
          isChanged: false,
          isConfigureChanged: false,
        },
      };

      dispatch(resetState(initState));
    }
  }, [adSetSelection?.selectedAdSetId, adsetDetails]);

  useEffect(() => {
    if (adsOtherState?.isLoading === false) {
      setAdSelection(ads);
    }
  }, [adsOtherState?.isLoading]);

  const setAdSelection = (items: AdsItemType[]) => {
    dispatch(
      setAdsSelection({
        selectedAds: items,
      })
    );
  };
  return {
    selectedAd,
    adAccountList,
    adAccountsSelection,
    setAdAccountSelection,
    adSetList,
    setAdSelection,
    adSetSelection,
    adsList: {
      data: ads?.sort((a: any, b: any) =>
        collator.compare(a?.name?.trim(), b?.name?.trim())
      ),
      ...adsOtherState,
    },
    setAdsetSelection,
    adsSelection,
  };
};
