import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAds, setAdsOtherState, setSelectedAd } from "store/actions/CreativeAI/Facebook/CreateVariant/createVariant";
import { CreateVariantsState } from "store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import {
  AdsItemType,
  FacebookInitialState,
} from "store/types/CreativeAI_Revamp/facebook.types";
import { FBHomeScreenInitialStateType } from "store/types/CreativeAI_Revamp/HomeScreen/homeScreen.types";
import { FETCH_CREATIVE_ADS_PAGINATION_LIMIT } from "../../utils/const";

export const useHomeLeftPanel = ({
  selectedAdIndex,
}: {
  selectedAdIndex: number;
}) => {
  const [sortSelect, setSortSelect] = useState<"Spends" | "CTR" | "CPR">(
    "Spends"
  );
  const [sortType, setSortType] = useState<"up" | "down">("down");
  const {
    adsSelection: { selectedAds },
    adAccountDetails,
  } = useSelector(
    (state: { fbHomeScreenReducer: FBHomeScreenInitialStateType }) =>
      state.fbHomeScreenReducer
  );
  const { resultMetrics } = useSelector(
    (state: { MainReducer: any }) => state.MainReducer
  );
  const { ads, adsOtherState, selectedAd, adsPaginationCursor } = useSelector(
    (state: { createVariantReducer: CreateVariantsState | any }) =>
      state.createVariantReducer
  );
  const { adAccountsSelection, adSetSelection, adsSelection } = useSelector(
    (state: { fbHomeScreenReducer: FBHomeScreenInitialStateType }) =>
      state.fbHomeScreenReducer
  );

  const dispatch = useDispatch();
  // useEffect(() => {
  //   if (localAds) {
  //     dispatch(setSelectedAd(localAds[selectedAdIndex]));
  //   }
  // }, [localAds]);
  // local variables and function
  const sortOption = useMemo(
    () => [
      {
        value: "Spends",
      },
      {
        value: "CTR",
      },
      {
        value: "CPR",
      },
    ],
    []
  );

  const onSortClick = () => {
    setSortType((prev) => (prev === "up" ? "down" : "up"));
  };

  // triggered when Load more button on left panel clicked
  const onLoadMoreAdsClick = useCallback(() => {
    if (!adSetSelection?.selectedAdSetId || !adAccountsSelection?.selectedAdAccountId) {
      console.error('Required parameters missing for loading more ads');
      return;
    }
    dispatch(
      getAds({
        adSetId: adSetSelection?.selectedAdSetId,
        adAccountId: adAccountsSelection?.selectedAdAccountId,
        adsCount: FETCH_CREATIVE_ADS_PAGINATION_LIMIT,
        after: adsPaginationCursor?.after
      })
    );
  }, [dispatch, adAccountsSelection, adSetSelection, adsPaginationCursor])

  return {
    selectedAd,
    sortSelect,
    setSortSelect,
    sortType,
    setSortType,
    onSortClick,
    sortOption,
    selectedAds,
    adsOtherState,
    adAccountDetails,
    resultMetrics,
    adsPaginationCursor,
    onLoadMoreAdsClick
  };
};
